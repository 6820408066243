import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Ecosystem from '../sections/welcome/Ecosystem';
import Navbar from '../sections/welcome/Navbar';
import Timeline from '../sections/welcome/Timeline';
import Reasons from '../sections/welcome/Reasons';
import Announcement from '../sections/welcome/Announcement';
import Disclaimer from '../sections/Disclaimer';



const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Welcome() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <Navbar/>
            <Announcement/>
            <Ecosystem/>
            <Timeline/>
            <Reasons/>
            <Disclaimer/>
          </div>    
      );
    }
    
  export default Welcome;