import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';


import wealth from "../../images/bb_wealth_small.jpg";



const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
        background: "linear-gradient(to bottom, rgb(35,23,58), rgb(32,12,122))",
        padding: theme.spacing(10, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      greenText: {
        color: "#50C878"
      },

      cardWealth: {
        backgroundColor: "rgb(47,93,79)",
        transition: 'box-shadow 0.3s',
        '&:hover': {
            boxShadow: '0 0 10px rgba(181, 244, 181, 0.6), 0 0 20px rgba(181, 244, 181, 0.4), 0 0 30px rgba(181, 244, 181, 0.2)',
        },
      },

      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
    }
});

function Info() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column">
                <Grid container item justifyContent="center">
                    <Box display="flex" flexDirection="column" justifyContent="center"  alignItems="center">
                        <Typography className={classes.whiteText} variant='h2'>
                            <strong>EARN <Typography className={classes.purpleText} variant='h2' display='inline'><strong>1-6% DAILY</strong></Typography>  ON YOUR BUSD</strong> 
                        </Typography>
                        <Typography className={classes.greyText} variant='h5' component='h5' display='block'>
                            No bots. No mining. No inflation. No BS.
                        </Typography>
                        
                        <Box mt={3}>
                        <Card className={classes.cardWealth}>
                                <CardContent>
                                    <Grid container direction='column' alignContent='center' justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Box
                                                component="img"
                                                alt="vault"
                                                src={wealth}   
                                                width="100%"
                                            
                                            />
                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>ROI: <Typography className={classes.wealthText} variant='h4' display='inline'>
                                                <strong>5% or 7% DAILY</strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>LAUNCHED: <Typography className={classes.wealthText} variant='h4' display='inline'>
                                                <strong>JULY 21</strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            


                                            

                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Button className={ classes.btnColor } variant="contained" size='large' href='/wealth'>
                                                <strong>ENTER DAPP</strong>
                                            </Button>

                                        </Grid>

                          
                                        
                                            

                                    </Grid>
                                </CardContent>
                            </Card>
                        </Box>
                        
                    </Box>
                    
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Info;