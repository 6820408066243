import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { borders } from '@material-ui/system';

import Calculator from './Calculator';
import { colors } from '@material-ui/core';

import { shortenAddress, useEthers } from '@usedapp/core';
import { constants, utils, BigNumber } from "ethers";


import { useGetTop3Depositors, useGetTop3Referrers, useViewUserInfo } from '../hooks/vaultHooks';




const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(40,40,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      greenText: {
        color: "#50C878"
      },
      boxCard: {
        padding: theme.spacing(2, 3, 2),
        minWidth: "16rem",
        backgroundColor: "rgb(40,40,40)"
      },
      stakingCard: {
        backgroundColor: "rgb(40,40,40)",
        minWidth: "20rem",
      },
      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
      textField: {
            borderColor: '#ffe699'
        },
        
      divider: {
        backgroundColor: "#ffe699",
        minHeight: "0.12rem"
      },
      notchedOutline: {
        borderColor: "#a6a6a6 !important",
      },
      textFieldColorRef: {
        color: "#a6a6a6 !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },
      leaderboard: {
        backgroundColor: "rgb(50,50,50)",
        padding: theme.spacing(2, 3, 2),
        // minWidth: "16rem"
      },
      winner: {
        backgroundColor: "#ffd042",
        // background: "linear-gradient(to right, #4568dc, #b06ab3)",
        color: "black"
      }
    }
});

function BankVault() {
    const classes = useStyles();

    let { account } = useEthers();

    const topThreeDepositors = useGetTop3Depositors();
    const topThreeReferrers = useGetTop3Referrers();
    const userInfo = useViewUserInfo(account);


    if (account) {
      account = shortenAddress(account);
    }


    return (
        <div className={ classes.container }>
            <Grid container spacing={8} direction="column" >
                <Grid container item>
                  <Grid container direction='column' alignItems='center'>
                    <Grid item>
                          <Typography className={classes.goldText} variant='h3'>
                              <strong>BANK VAULT REWARDS</strong> 
                          </Typography>
                      </Grid>
                      
                      <Grid item>
                          <Typography className={classes.greyText} variant='h6'>
                              <strong>Win up to 2% of the TVL (Total Value Locked) on a weekly basis</strong> 
                          </Typography>
                      </Grid>

                  </Grid>
                    
                </Grid>

                <Grid container item>
                  <Grid container direction='row' justifyContent='space-evenly' spacing={5}>
                    <Grid container item md={6}>
                  
                      <Grid className={ classes.leaderboard } container direction="column" spacing={2}>
                        <Grid item>
                          <Typography className={ classes.goldText } variant="h4" align="center">
                            <strong>TOP DEPOSITOR</strong>
                          </Typography>
                          <Typography className={ classes.greyText } variant="body1" align="center">
                            The top weekly depositor wins 1% of the TVL
                          </Typography>
                        </Grid>
                        {topThreeDepositors.map((d, i) => (
                          <Grid className={ i === 0 ? classes.winner : classes.whiteText } item container key={d.name}>
                            <Grid item xs={5}>
                              <Typography variant="body1" align="left">
                                {i + 1}. {d.address}
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="body1" align="right">
                                {d.deposit}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                        
                        
                        <Grid item >
                          <Typography className={ classes.whiteText } variant="body1" align="center">
                            Your weekly deposit: {userInfo ? parseFloat(utils.formatEther(userInfo[4])).toFixed(2) : 0}
                          </Typography>
                        </Grid>
                        
                        {account == topThreeDepositors[0].address && (
                          <Typography className={ classes.greenText } variant="body2" align="center">
                            <strong>You are the top depositor.</strong> 
                          </Typography>
                        )}

                        {account != topThreeDepositors[0].address && (
                          <Typography className={ classes.greyText } variant="body2" align="center">
                            You are not the top depositor
                          </Typography>
                        )}
                        
                      </Grid>
                    
                    </Grid>

                    <Grid container item md={6}>
                  
                      <Grid className={ classes.leaderboard } container direction="column" spacing={2}>
                        <Grid item>
                          <Typography className={ classes.goldText } variant="h4" align="center">
                            <strong>TOP REFERRER</strong>
                          </Typography>
                          <Typography className={ classes.greyText } variant="body1" align="center">
                            The top weekly referrer wins 1% of the TVL
                          </Typography>
                        </Grid>
                        {topThreeReferrers.map((d, i) => (
                          <Grid className={ i === 0 ? classes.winner : classes.whiteText } item container key={d.name}>
                            <Grid item xs={5}>
                              <Typography variant="body1" align="left">
                                {i + 1}. {d.address}
                              </Typography>
                            </Grid>
                            <Grid item xs={7}>
                              <Typography variant="body1" align="right">
                                {d.deposit}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))}
                        
                        
                        <Grid item >
                          <Typography className={ classes.whiteText } variant="body1" align="center">
                            Your weekly referrals: {userInfo ? parseFloat(utils.formatEther(userInfo[5])).toFixed(2) : 0}
                          </Typography>
                        </Grid>
                        
                        {account == topThreeReferrers[0].address && (
                          <Typography className={ classes.greenText } variant="body2" align="center">
                            <strong>You are the top referrer.</strong> 
                          </Typography>
                        )}

                        {account != topThreeReferrers[0].address && (
                          <Typography className={ classes.greyText } variant="body2" align="center">
                            You are not the top referrer
                          </Typography>
                        )}
                        
                      </Grid>
                    
                    </Grid>


                  </Grid>

                </Grid>

                <Grid item>
                  <Typography className={ classes.goldText } variant="h4" align="center">
                    <strong>NEXT WINNERS PICKED ON: </strong>
                  </Typography>

                </Grid>
                
            </Grid>
        </div>    
    );
  }
  
export default BankVault;