import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";
import { shortenAddress } from '@usedapp/core';


import {
  bep_20_contract,

  nodes_interface,
  nodes_address,

  gem_contract,
  gem_interface,
  gem_address,
  nodes_contract,

} from '../../constants/contracts';



export function useApproveBUSD(){
    const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, events, resetState };
}


// MINT
export function useMint(){
  const { state, send, events, resetState } = useContractFunction(gem_contract, "safeMint");
  return { state, send, events, resetState };
}

export function useViewTokenCount() {
    const [tokenCount] = useContractCall({
      abi: gem_interface,
      address: gem_address,
      method: "viewTokenCount",
      args: [], 
    }) ?? [];
    
    return tokenCount;
  }

export function useOwnsGemNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: nodes_interface,
    address: nodes_address,
    method: "ownsGemNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}


// STAKE
export function useViewUserInfo(tier) {
    const { account } = useEthers();
    const userInfo = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "viewUserInfo",
      args: [account, tier], 
    }) ?? [];
    
    if (userInfo.length !== 0){
      return userInfo;
    }
    return [0, 0, 0, 0, 0, 0];
  }

  export function useViewUserEstYield(tier) {
    const { account } = useEthers();
    const [dailyYield] = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "viewUserEstYield",
      args: [account, tier], 
    }) ?? [];
    
    return dailyYield;
  }
  
  export function useCalculateReward(tier) {
    const { account } = useEthers();
    const [reward] = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "calculateReward",
      args: [account, tier], 
    }) ?? [];
    
    return reward;
  }

  export function useOwnsKeyNFT() {
    const { account } = useEthers();
    const [ownsNft] = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "ownsBankKeyNFT",
      args: [account], 
    }) ?? [];
    
    return ownsNft;
  }

  export function useHasBusdBankStake() {
    const { account } = useEthers();
    const [hasStake] = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "hasBusdBankStake",
      args: [account], 
    }) ?? [];
    
    return hasStake;
  }

  export function useStake(){
    const { state, send, events, resetState } = useContractFunction(nodes_contract, "stake");
    return { state, send, events, resetState };
  }
  
  export function useCompound(){
    const { state, send, events, resetState } = useContractFunction(nodes_contract, "compound");
    return { state, send, events, resetState };
  }
  
  export function useWithdrawRewards(){
    const { state, send, events, resetState } = useContractFunction(nodes_contract, "withdrawRewards");
    return { state, send, events, resetState };
  }


  // VAULT 

  export function useViewUserInfo2(account, tier) {
    const userInfo = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "viewUserInfo",
      args: [account, tier], 
    }) ?? [];
    
    if (userInfo.length !== 0){
      return userInfo;
    }
    return [0, 0, 0, 0, 0, 0];
  }

  export function useGetTop3Depositors() {
    let depositors = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "getTop3Depositors",
      args: [], 
    }) ?? [];

    if (depositors.length > 0){
        depositors = depositors[0];
    }
    
    let data_map = []; 

    let userInfo = useViewUserInfo2(depositors[0], 0);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[0]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }
    

    userInfo = useViewUserInfo2(depositors[1], 0);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[1]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    userInfo = useViewUserInfo2(depositors[2], 0);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[2]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    return data_map
  }

  export function useGetTop3Referrers() {
    let referrers = useContractCall({
      abi: nodes_interface,
      address: nodes_address,
      method: "getTop3Referrers",
      args: [], 
    }) ?? [];
    
    if (referrers.length > 0){
        referrers = referrers[0];
    }
    
    let data_map = []; 

    let userInfo = useViewUserInfo2(referrers[0], 0);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[0]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }
    

    userInfo = useViewUserInfo2(referrers[1], 0);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[1]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    userInfo = useViewUserInfo2(referrers[2], 0);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[2]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    return data_map
  }

  