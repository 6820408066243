import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";

import {
  bep_20_contract,
  bep_20_interface,
  bep_20_address,

  vault2_interface,
  vault2_address,
  vault2_contract,

} from '../../constants/contracts';

export function useViewUserInfo() {
  const { account } = useEthers();
  const userInfo = useContractCall({
    abi: vault2_interface,
    address: vault2_address,
    method: "viewUserInfo",
    args: [account], 
  }) ?? [];
  
  if (userInfo.length !== 0){
    return userInfo;
  }
  return [0, 0, 0];
}

export function useViewUserEstYield() {
  const { account } = useEthers();
  const [dailyYield] = useContractCall({
    abi: vault2_interface,
    address: vault2_address,
    method: "viewUserEstYield",
    args: [account], 
  }) ?? [];
  
  return dailyYield;
}

export function useCalculateReward() {
  const { account } = useEthers();
  const [reward] = useContractCall({
    abi: vault2_interface,
    address: vault2_address,
    method: "calculateReward",
    args: [account], 
  }) ?? [];
  
  return reward;
}

export function useOwnsNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: vault2_interface,
    address: vault2_address,
    method: "ownsNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}


export function useApproveBUSD(){
    const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, events, resetState };
}


export function useStake(){
  const { state, send, events, resetState } = useContractFunction(vault2_contract, "stake");
  return { state, send, events, resetState };
}

export function useCompound(){
  const { state, send, events, resetState } = useContractFunction(vault2_contract, "compound");
  return { state, send, events, resetState };
}

export function useWithdrawRewards(){
  const { state, send, events, resetState } = useContractFunction(vault2_contract, "withdrawRewards");
  return { state, send, events, resetState };
}