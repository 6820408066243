import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { borders } from '@material-ui/system';

import Snackbars from '../components/Snackbars';


import bankKey from "../images/bank_key.jpg";

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';

import { bank_key_address, bep_20_address } from "../constants/contracts";
import { useApproveBUSD, useMint, useViewTokenCount, useOwnsNFT } from '../hooks/mintNFTHooks';

const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(40,40,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      redText: {
        color: "#FF0000"
      },
      card: {
        padding: theme.spacing(7, 8, 7),
        backgroundColor: "rgb(50,50,50)"
      },
      btnColor: {
        minWidth: "70%",
        alignSelf:"center",
        color: "#ffffff",
        backgroundColor: "#6c531e",
        "&:hover": {
            color: "#ffffff",
            backgroundColor:"#4d3b15",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
    }
});

function MintNFT() {
    const classes = useStyles();
    const { account } = useEthers();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const totalMinted = useViewTokenCount();
    const ownsNft = useOwnsNFT();

    // mint nft
    const { state: mintState, send: mint, events: mintEvents, resetState: resetMint} = useMint();
    function handleMint(){        
        mint(account, utils.parseEther("150")).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (mintState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (mintState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (mintState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [mintState]);


    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(bank_key_address, utils.parseEther("150")).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" >
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid item>
                    <Box display="flex" flexDirection="column">
                        <Typography className={classes.goldText} variant='h3'>
                            <strong>MINT A BANK KEY NFT</strong> 
                        </Typography>
                        <Typography className={classes.greyText} variant='h6' component='h5' display='block'>
                            Minting a BANK KEY NFT grants you an additional 1% daily return in BUSD BANK VAULT. 
                        </Typography>
                        <Typography className={classes.greyText} variant='h6' component='h5' display='block'>
                            And additional benefits in future ecosystem projects.
                        </Typography>
                    </Box>
                </Grid>

                <Grid container item>
                    <Grid container direction="row" className={ classes.card }>
                        <Grid item>
                            <Box display="flex" flexDirection="column">
                                <Box
                                    component="img"
                                    alt="BANK KEY NFT"
                                    src={bankKey}
                                    height="auto"
                                    width="75%"
                                    alignSelf="center"
                                    />
                                <Typography className={classes.whiteText} variant='h6' align='center'>
                                    <strong>Total Minted: {totalMinted ? utils.formatUnits(totalMinted, 0) : "0"}/999</strong> 
                                </Typography>
                            </Box>
                            
                            
                        </Grid>

                        <Grid container item lg={5}>
                            <Grid container direction='column' justifyContent='center'>
                                
                                <Typography className={classes.goldText} variant='h4' align='center'>
                                    <strong>MINT YOUR BANK KEY NFT</strong> 
                                </Typography>
                                
                                
                                <Typography align='center'>
                                    {ownsNft ? <Typography className={classes.greenText} variant='h6'><strong>You own a BANK KEY NFT</strong></Typography> : <Typography className={classes.greyText} variant='h6'><strong>You currently do not own a BANK KEY NFT</strong></Typography>}
                                </Typography>
                                <Button className={ classes.btnColor } target="_blank" variant="outlined" onClick={() => handleApproveBUSD()}>
                                    <strong>Approve 150 BUSD</strong> 
                                </Button>
                                <Button className={ classes.btnColor } target="_blank" variant="outlined" onClick={() => handleMint()}>
                                    <strong>MINT</strong> 
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default MintNFT;