import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";
import { shortenAddress } from '@usedapp/core';


import {
  bep_20_contract,
  bep_20_interface,
  bep_20_address,

  busd_bank_interface,
  busd_bank_address,
  busd_bank_contract,

} from '../constants/contracts';

export function useViewUserInfo(account) {
  const userInfo = useContractCall({
    abi: busd_bank_interface,
    address: busd_bank_address,
    method: "viewUserInfo",
    args: [account], 
  }) ?? [];
  
  if (userInfo.length !== 0){
    return userInfo;
  }
  return [0, 0, 0, 0, 0, 0, false];
}

export function useGetTop3Depositors() {
    let depositors = useContractCall({
      abi: busd_bank_interface,
      address: busd_bank_address,
      method: "getTop3Depositors",
      args: [], 
    }) ?? [];

    if (depositors.length > 0){
        depositors = depositors[0];
    }
    
    let data_map = []; 

    let userInfo = useViewUserInfo(depositors[0]);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[0]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }
    

    userInfo = useViewUserInfo(depositors[1]);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[1]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    userInfo = useViewUserInfo(depositors[2]);
    if (depositors.length > 0) {
        data_map.push({"address": shortenAddress(depositors[2]), "deposit": parseFloat(utils.formatEther(userInfo[4])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    return data_map
  }

  export function useGetTop3Referrers() {
    let referrers = useContractCall({
      abi: busd_bank_interface,
      address: busd_bank_address,
      method: "getTop3Referrers",
      args: [], 
    }) ?? [];
    
    if (referrers.length > 0){
        referrers = referrers[0];
    }
    
    let data_map = []; 

    let userInfo = useViewUserInfo(referrers[0]);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[0]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }
    

    userInfo = useViewUserInfo(referrers[1]);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[1]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    userInfo = useViewUserInfo(referrers[2]);
    if (referrers.length > 0) {
        data_map.push({"address": shortenAddress(referrers[2]), "deposit": parseFloat(utils.formatEther(userInfo[5])).toFixed(2)})
    }
    else{
        data_map.push({"address": shortenAddress("0x0000000000000000000000000000000000000000"), "deposit": 0})
    }

    return data_map
  }