import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { borders } from '@material-ui/system';

import Snackbars from '../../components/Snackbars';

import { useSearchParams, createSearchParams } from "react-router-dom"

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';

import { bep_20_address, wealth_address } from "../../constants/contracts";
import { useOwnsBankKeyNFT, useOwnsGemNFT, useHasBusdBankStake, useHasNodesStake, useHasNotEmergencyWithdraw, useChecklistComplete, useStake, useViewUserInfo, useApproveBUSD, useViewUserEstYield, useCompound, useWithdrawRewards, useCalculateReward } from '../../hooks/wealth/wealthHooks';



const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(47,93,79)"

    return {
      container: {
          backgroundColor: "#418570",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      redText: {
        color: "#fa6464"
      },
      greenText: {
        color: "#50C878"
      },
      wealthText: {
        color: "rgb(181, 244, 181)"
      },
      boxCard: {
        padding: theme.spacing(2, 3, 2),
        minWidth: "16rem",
        backgroundColor: "rgb(47,93,79)"
      },
      stakingCard: {
        backgroundColor: "rgb(47,93,79)",
        minWidth: "20rem",
      },
      btnColor: {
        color: "rgb(181, 244, 181)",
        backgroundColor: btnColor,
        "&:hover": {
            color: btnColor,
            backgroundColor:"rgb(181, 244, 181)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
      emergencyBtnColor: {
        color: "#E97451",
        backgroundColor: btnColor,
        
      },
      textField: {
            borderColor: '#ffe699'
        },
        
      divider: {
        backgroundColor: "#418570",
        minHeight: "0.12rem"
      },
      notchedOutline: {
        borderColor: "#a6a6a6 !important",
      },
      textFieldColorRef: {
        color: "#a6a6a6 !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },

      textField: {
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: 'green',
          },
        },
        '& .MuiInputLabel-outlined': {
            color: 'white',
          },
      },
    }
});

function Stake() {
    const classes = useStyles();
    const { account } = useEthers();

    // referrer
    const [searchParams, setSearchParams] = useSearchParams({});
    const myReferrer = searchParams.get("ref"); 
    const [ref, setRef] = useState("");  
    useEffect(() => {
        if(!myReferrer) {
            if (ref === "") {
                setRef("0x0000000000000000000000000000000000000000");
            }
        }
        else{
            if (ref === "") {
                setRef(myReferrer);
            }
        }
      }, [myReferrer]);

    const [refLink, setRefLink] = useState("");
    if ((account !== undefined) && (refLink === "")){
        setRefLink(`https://busd-bank.com/wealth?ref=${account}`);
    }


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const tokenBalance = useTokenBalance(bep_20_address, account);
    const TVL = useTokenBalance(bep_20_address, wealth_address);

    const ownsBankKeyNFT = useOwnsBankKeyNFT();
    const ownsGemNft = useOwnsGemNFT();
    const hasBusdBankStake = useHasBusdBankStake();
    const hasNodesStake = useHasNodesStake();
    const hasNotEmergencyWithdraw = useHasNotEmergencyWithdraw();
    const checklistComplete = useChecklistComplete();

    const userInfo = useViewUserInfo();
    const dailyYield = useViewUserEstYield();
    const rewards = useCalculateReward();

    const [approveBUSDSpend, setApproveBUSDSpend] = useState(undefined);
    const [stake, setStake] = useState(undefined);

    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(wealth_address, utils.parseEther(approveBUSDSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);


     // Use BUSD
     const { state: stakeBUSDState, send: stakeBUSD, events: stakeBUSDEvents, resetState: resetStakeBUSD } = useStake();
     function handleStake(){
        stakeBUSD(utils.parseEther(stake), ref).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
     }
 
     useEffect(() => {
         if (stakeBUSDState.status === "Success") {
             setOpen(true);
             setSeverity("success");
         }
         else if (stakeBUSDState.status === "Mining") {
             setOpen(true);
             setSeverity("info");
         }
         else if (stakeBUSDState.status === "Fail") {
             setOpen(true);
             setSeverity("error");
         }
         
     }, [stakeBUSDState]);


    // Compound
    const { state: compoundState, send: compound, events: compoundEvents, resetState: resetCompound } = useCompound();
    function handleCompound(){        
        compound().catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (compoundState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (compoundState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (compoundState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [compoundState]);


    // Collect
    const { state: withdrawRewardsState, send: withdrawRewards, events: withdrawRewardsEvents, resetState: resetWithdrawRewards } = useWithdrawRewards();
    function handleWithdrawRewards(){        
        withdrawRewards().catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (withdrawRewardsState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (withdrawRewardsState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (withdrawRewardsState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [withdrawRewardsState]);

    return (
        <div className={ classes.container }>
            <Grid container spacing={8} direction="column" >
                <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid container item>
                    <Grid container direction='row' justifyContent='space-evenly' spacing={3}>
                        <Grid item className={ classes.boxCard }>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.wealthText } variant='h6' align='center'>
                                    <strong>Total Value Locked</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                <Typography className={ classes.whiteText } variant='h6' align='center'>
                                    <strong>{TVL ? parseFloat(utils.formatEther(TVL)).toFixed() : "0"} BUSD</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid container direction='row' justifyContent='center' spacing={8}>
                        <Grid item lg={4}>
                            <Card className={ classes.stakingCard }>
                                <CardHeader title=<Typography className={ classes.wealthText } variant='h6'>
                                    <strong>STAKE BUSD</strong> 
                                </Typography>/>
                                <CardContent>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>WALLET BALANCE: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed() : "0"} </strong>
                                    </Typography>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>STAKED: {userInfo ? parseFloat(utils.formatEther(userInfo[0])).toFixed() : "0"} </strong> 
                                    </Typography>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>EST. DAILY YIELD: {dailyYield ? parseFloat(utils.formatEther(dailyYield)).toFixed(2) : "0"} </strong> 
                                    </Typography>

                                    
                                    <Box display='flex' flexDirection='column' mt={4}>
                                        <Box mb={1}>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>Approve BUSD</strong> 
                                            </Typography>
                                        </Box>
                                        

                                        <TextField
                                        type="number"
                                        className={ classes.textField }
                                        InputProps={{ className: classes.whiteText }}
                                        InputLabelProps={{ className: classes.greyText }}
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setApproveBUSDSpend(event.target.value)}
                                        />

                                        <Button className={ classes.btnColor } variant="contained" onClick={() => handleApproveBUSD()}>
                                            <strong>APPROVE</strong>
                                        </Button>
                                    </Box>

                                    <Box display='flex' flexDirection='column' mt={1} mb={4}>
                                        <Box mb={1}>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>Stake BUSD</strong> 
                                            </Typography>
                                        </Box>
                                        

                                        <TextField
                                        type="number"
                                        className={ classes.textField }
                                        InputProps={{ className: classes.whiteText }}
                                        InputLabelProps={{ className: classes.greyText }}
                                        label="Enter Amount (MIN. 10 BUSD)" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setStake(event.target.value)}
                                        value = {stake ? stake : ""}
                                        />
                                        
                                        <Button className={ classes.btnColor } variant="contained" onClick={() => handleStake()}>
                                            <strong>STAKE</strong>
                                        </Button>

                                        <Box display='flex' flexDirection='row' justifyContent="center" mt={2}>
                                        
                                            <Typography className={ classes.greyText } variant='body2'>
                                            <strong>There is a 10% staking fee</strong> 
                                            </Typography>

                                        </Box>
                                    </Box>

                                    
                                    

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={4}>
                            <Card className={ classes.stakingCard }>
                                <CardHeader title=<Typography className={ classes.wealthText } variant='h6'>
                                    <strong>COMPOUND/COLLECT</strong> 
                                </Typography>/>
                                <CardContent>
                                    
                                    
                                    
                                    
                                    <Box display='flex' flexDirection='column' mb={4}>
                                        <Box display='flex' flexDirection='row' justifyContent='center'>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>BUSD EARNED: {rewards ? parseFloat(utils.formatEther(rewards)).toFixed(2) : "0"}</strong> 
                                            </Typography>
                                                
                                            
                                        </Box>
                                        
                                        <Box display='flex' flexDirection='row' justifyContent='center' mt={2}>
                                            <Box mr={1}>
                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleCompound()}>
                                                    <strong>COMPOUND</strong>
                                                </Button>
                                            </Box>
                                            
                                            <Box ml={1}>
                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleWithdrawRewards()}>
                                                    <strong>COLLECT</strong>
                                                </Button>
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                    
                                    
                                    <Divider className={ classes.divider }/>

                                    <Box display='flex' flexDirection='column' mt={4}>
                                        <Box display='flex' justifyContent='center'>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>REFERRAL LINK (EARN 4%)</strong> 
                                            </Typography>
                                                
                                            
                                        </Box>

                                        <Box mt={2} justifyContent="center" display="flex">
                                
                                                <TextField
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: classes.notchedOutline,
                                                            input: classes.textFieldColorRef
                                                        },
                                                        readOnly: true
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={refLink}
                                                    
                                                />

                                                <Button className={classes.btnColor} onClick={() =>  navigator.clipboard.writeText(`${refLink}`)}>Copy</Button>
                                
                                        </Box>

                                        <Box display='flex' flexDirection='row' justifyContent='center' mt={2}>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>REFERRAL REWARDS EARNED: {userInfo ? parseFloat(utils.formatEther(userInfo[3])).toFixed(2) : "0"}</strong> 
                                            </Typography>
                                            
                                                
                                            
                                        </Box>
                                        <Typography className={ classes.greyText } variant='caption'>
                                        <strong>Referral rewards are automatically deposited into your wallet</strong> 
                                        </Typography>


                                    </Box>
                                    

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item lg={4}>
                            <Card className={ classes.stakingCard }>
                                <CardHeader title=<Typography className={ classes.wealthText } variant='h6'>
                                    <strong>MY YIELD CHECKLIST</strong> 
                                </Typography>/>
                                <CardContent>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>EARN <Typography className={ classes.wealthText } variant='body1' display='inline'><strong>7%</strong></Typography>  BY COMPLETING ALL OF THE FOLLOWING:  </strong>
                                    </Typography>

                                    <Box display='flex' flexDirection='column' mt={2} justifyContent='center' alignSelf='center'>
                                    <Typography className={ ownsBankKeyNFT ? classes.greenText : classes.redText } variant='body1'>
                                    <strong>OWN A BANK KEY NFT: {ownsBankKeyNFT ? "COMPLETE" : "INCOMPLETE"} </strong>
                                    </Typography>
                                    <Typography className={ ownsGemNft ? classes.greenText : classes.redText } variant='body1'>
                                    <strong>OWN A DIAMOND GEM NFT: {ownsGemNft ? "COMPLETE" : "INCOMPLETE"} </strong>
                                    </Typography>
                                    <Typography className={ hasBusdBankStake ? classes.greenText : classes.redText } variant='body1'>
                                    <strong>2000+ STAKE IN BUSD BANK VAULT: {hasBusdBankStake ? "COMPLETE" : "INCOMPLETE"} </strong>
                                    </Typography>
                                    <Typography className={ hasNodesStake ? classes.greenText : classes.redText } variant='body1'>
                                    <strong>1500+ STAKE IN BUSD BANK NODES: {hasNodesStake ? "COMPLETE" : "INCOMPLETE"} </strong>
                                    </Typography>
                                    <Typography className={ hasNotEmergencyWithdraw ? classes.greenText : classes.redText } variant='body1'>
                                    <strong>HAVE NOT EMERGENCY WITHDRAWN FROM VAULT: {hasNotEmergencyWithdraw ? "COMPLETE" : "INCOMPLETE"} </strong>
                                    </Typography>
                                    </Box>
                                    

                                    
                                    <Box display='flex' flexDirection='column' mt={4} justifyContent='center' alignSelf='center'>
                                        <Box mb={1}>
                                            <Typography className={ checklistComplete ? classes.wealthText : classes.whiteText } variant='body1' align='center'>
                                            <strong>MY DAILY YIELD: { checklistComplete ? "7%" : "5%" }</strong> 
                                            </Typography>
                                        </Box>
                                        <Box mb={1}>
                                            <Typography className={ checklistComplete ? classes.wealthText : classes.whiteText } variant='body2' align='center'>
                                            <strong>{ checklistComplete ? "Congratulations! You have completed all items on the checklist" : "You have NOT completed all items on the checklist" }</strong> 
                                            </Typography>
                                        </Box>
                                        

                                        
                                    </Box>

                                    
                                    

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                
              
                
            </Grid>
        </div>    
    );
  }
  
export default Stake;