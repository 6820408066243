import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';




const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(50,50,50)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      cardWealth: {
        backgroundColor: "rgb(47,93,79)",
        transition: 'box-shadow 0.3s',
        '&:hover': {
            boxShadow: '0 0 10px rgba(181, 244, 181, 0.6), 0 0 20px rgba(181, 244, 181, 0.4), 0 0 30px rgba(181, 244, 181, 0.2)',
        },
      },

      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
    }
});

function Info() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column">
                <Grid container item justifyContent="center">
                    <Box display="flex" flexDirection="column" justifyContent="center"  alignItems="center">
                        <Typography className={classes.whiteText} variant='h2'>
                            <strong>EARN <Typography className={classes.goldText} variant='h2' display='inline'><strong>0.5-1% DAILY</strong></Typography>  ON YOUR BUSD</strong> 
                        </Typography>
                        <Typography className={classes.greyText} variant='h5' component='h5' display='block'>
                            No bots. No mining. No inflation. No BS. No Fees.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Info;