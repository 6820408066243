import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';

import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import vault from "../../images/vault_black.png";
import nodes from "../../images/nodes_small.png";
import wealth from "../../images/bb_wealth_small.jpg";
import vault2 from "../../images/vault2_small.jpg";




const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(50,50,50)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      greenText: {
        color: "#50C878"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      secondaryText: {
        color: "rgb(164, 158, 189)"
      },
      wealthText: {
        color: "rgb(181, 244, 181)"
      },

      cardVault: {
        backgroundColor: "rgb(40,40,40)",
        transition: 'box-shadow 0.3s',
        '&:hover': {
            boxShadow: '0 0 10px rgba(111, 235, 235, 0.6), 0 0 20px rgba(111, 235, 235, 0.4), 0 0 30px rgba(111, 235, 235, 0.2)',
        },
      },

      cardNodes: {
        background: "linear-gradient(to top, rgb(35,23,58), rgb(32,12,122))",
        transition: 'box-shadow 0.3s',
        '&:hover': {
            boxShadow: '0 0 10px rgba(164, 168, 255, 0.6), 0 0 20px rgba(164, 168, 255, 0.4), 0 0 30px rgba(164, 168, 255, 0.2)',
        },
      },

      cardWealth: {
        backgroundColor: "rgb(47,93,79)",
        transition: 'box-shadow 0.3s',
        '&:hover': {
            boxShadow: '0 0 10px rgba(181, 244, 181, 0.6), 0 0 20px rgba(181, 244, 181, 0.4), 0 0 30px rgba(181, 244, 181, 0.2)',
        },
      },

      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },

      

      textMt: {
        marginTop: "2rem"
      },

      center: {
        alignSelf: 'center'
      }

    }
});

function Timeline() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={5} direction="column" alignItems='center'>
                <Grid item>
                    <Typography className={classes.goldText} variant='h3'>
                        <strong>PROJECT RELEASES</strong> 
                    </Typography>
               

                </Grid>

                <Grid container item>
                    <Grid container spacing={5} justifyContent='space-evenly'>
                        <Grid item lg={4}>
                            <Card className={classes.cardVault}>
                                <CardContent>
                                    <Grid container direction='column' alignContent='center' justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Box
                                                component="img"
                                                alt="vault"
                                                src={vault}   
                                                width="100%"
                                            
                                            />
                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>ROI: <Typography className={classes.goldText} variant='h4' display='inline'>
                                                <strong>3-4% DAILY </strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>LAUNCHED: <Typography className={classes.goldText} variant='h4' display='inline'>
                                                <strong>JUNE 30TH </strong> 
                                            </Typography></strong> 
                                            </Typography>

                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Button className={ classes.btnColor } variant="contained" size='large' href='/vault'>
                                                <strong>ENTER DAPP</strong>
                                            </Button>

                                        </Grid>
                                            

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid item lg={4}>
                            <Card className={classes.cardNodes}>
                                <CardContent>
                                    <Grid container direction='column' alignContent='center' justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Box
                                                component="img"
                                                alt="vault"
                                                src={nodes}   
                                                width="100%"
                                            
                                            />
                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>ROI: <Typography className={classes.purpleText} variant='h4' display='inline'>
                                                <strong>1-6% DAILY</strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>LAUNCHED: <Typography className={classes.purpleText} variant='h4' display='inline'>
                                                <strong>JULY 7TH </strong> 
                                            </Typography></strong> 
                                            </Typography>


                                            

                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Button className={ classes.btnColor } variant="contained" size='large' href='/nodes'>
                                                <strong>ENTER DAPP</strong>
                                            </Button>

                                        </Grid>
                                        
                                            

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid item lg={4}>
                            <Card className={classes.cardWealth}>
                                <CardContent>
                                    <Grid container direction='column' alignContent='center' justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Box
                                                component="img"
                                                alt="vault"
                                                src={wealth}   
                                                width="100%"
                                            
                                            />
                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>ROI: <Typography className={classes.wealthText} variant='h4' display='inline'>
                                                <strong>5% or 7% DAILY</strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            

                                            
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>LAUNCHED: <Typography className={classes.wealthText} variant='h4' display='inline'>
                                                <strong> JULY 21</strong> 
                                            </Typography></strong> 
                                            </Typography>


                                            

                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Button className={ classes.btnColor } variant="contained" size='large' href='/wealth'>
                                                <strong>ENTER DAPP</strong>
                                            </Button>

                                        </Grid>

                          
                                        
                                            

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                        <Grid item lg={4}>
                            <Card className={classes.cardVault}>
                                <CardContent>
                                    <Grid container direction='column' alignContent='center' justifyContent='center' alignItems='center'>
                                        <Grid item>
                                            <Box
                                                component="img"
                                                alt="vault"
                                                src={vault2}   
                                                width="100%"
                                            
                                            />
                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>ROI: <Typography className={classes.goldText} variant='h4' display='inline'>
                                                <strong>0.5-1% DAILY </strong> 
                                            </Typography></strong> 
                                            </Typography>

                                            <Typography className={classes.whiteText} variant='h4' align='center'>
                                                <strong>LAUNCHED: <Typography className={classes.goldText} variant='h4' display='inline'>
                                                <strong>JULY 29TH </strong> 
                                            </Typography></strong> 
                                            </Typography>

                                        </Grid>

                                        <Grid item className={classes.textMt}>
                                            <Button className={ classes.btnColor } variant="contained" size='large' href='/vault_two'>
                                                <strong>ENTER DAPP</strong>
                                            </Button>

                                        </Grid>
                                            

                                    </Grid>
                                </CardContent>
                            </Card>

                        </Grid>

                    </Grid>

                </Grid>
           
            

                
            </Grid>
        </div>    
    );
  }
  
export default Timeline;