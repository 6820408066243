import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useEthers, shortenAddress } from '@usedapp/core';
import ConnectWalletBtn from '../../components/ConnectWalletBtn';


import Logo from "../../images/bb_wealth_small.jpg";


const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(47,93,79)"

    return {
        appBar: {
            backgroundColor: "rgb(47,93,79)",
            boxShadow: "None",
            paddingBottom: theme.spacing(1)
        },
        logo: {
            height: 'auto',
            width: '40%'
        },
        wealthText: {
          color: "rgb(181, 244, 181)"
        },
        buttonMargin: {
            marginTop: theme.spacing(3)
          },
          btnColor: {
            color: "rgb(181, 244, 181)",
            backgroundColor: btnColor,
            "&:hover": {
                color: btnColor,
                backgroundColor:"rgb(181, 244, 181)",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: btnColor
              }
            }
          },
    }
});

function Navbar() {
    const classes = useStyles();

    return (
        <div >
            <AppBar className={ classes.appBar } position="relative">
                <Toolbar>
                <Grid container direction="row">
                    <Grid container item>
                    <Box 
                          className={ classes.logo }
                          component="img"
                          alt="BUSD BANK Logo"
                          src={Logo}
                          mt={5}
                      />
                    </Grid>
                </Grid>
                <Grid container item direction='row' lg={6} md={6} xs={6} alignContent="center" className={ classes.buttonMargin } justifyContent='space-between'>
                  <Box mt={2} >
                      <Button className={ classes.btnColor } variant="contained" href='https://the-stamp.com/2023/07/busd-bank-wealth/' target="_blank">
                          <strong>AUDIT (THE STAMP)</strong>
                      </Button>
                    </Box>
                  <Box mt={2} >
                      <Button className={ classes.btnColor } variant="contained" href='https://docs.busd-bank.com' target="_blank">
                          <strong>DOCUMENTATION</strong>
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <Button className={ classes.btnColor } variant="contained" href='https://t.me/+bF4H93wS3B00NWZh' target="_blank">
                          <strong>TELEGRAM</strong>
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <ConnectWalletBtn classes={classes}/>
                    </Box>
                  </Grid>
                </Toolbar>
            </AppBar>
        </div>    
    );
  }
  
export default Navbar;

