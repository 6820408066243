import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { borders } from '@material-ui/system';

import Snackbars from '../../components/Snackbars';
import Calculator from './Calculator';

import { useSearchParams, createSearchParams } from "react-router-dom"

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';

import { vault2_address, bep_20_address } from "../../constants/contracts";
import { useOwnsNFT, useStake, useViewUserInfo, useApproveBUSD, useViewUserEstYield, useCompound, useWithdrawRewards, useCalculateReward } from '../../hooks/vault2/stakeHooks';



const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(50,50,50)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      redText: {
        color: "#FF0000"
      },
      greenText: {
        color: "#50C878"
      },
      boxCard: {
        padding: theme.spacing(2, 3, 2),
        minWidth: "16rem",
        backgroundColor: "rgb(40,40,40)"
      },
      stakingCard: {
        backgroundColor: "rgb(40,40,40)",
        minWidth: "20rem",
      },
      btnColor: {
        color: "#ffe699",
        backgroundColor: btnColor,
        "&:hover": {
            color: "rgb(50,50,50)",
            backgroundColor:" #ffe699",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
      emergencyBtnColor: {
        color: "#E97451",
        backgroundColor: btnColor,
        
      },
      textField: {
            borderColor: '#ffe699'
        },
        
      divider: {
        backgroundColor: "#ffe699",
        minHeight: "0.12rem"
      },
      notchedOutline: {
        borderColor: "#a6a6a6 !important",
      },
      textFieldColorRef: {
        color: "#a6a6a6 !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },
    }
});

function Stake() {
    const classes = useStyles();
    const { account } = useEthers();

    // referrer
    const [searchParams, setSearchParams] = useSearchParams({});
    const myReferrer = searchParams.get("ref"); 
    const [ref, setRef] = useState("");  
    useEffect(() => {
        if(!myReferrer) {
            if (ref === "") {
                setRef("0x0000000000000000000000000000000000000000");
            }
        }
        else{
            if (ref === "") {
                setRef(myReferrer);
            }
        }
      }, [myReferrer]);

    const [refLink, setRefLink] = useState("");
    if ((account !== undefined) && (refLink === "")){
        setRefLink(`https://busd-bank.com/vault_two?ref=${account}`);
    }


    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const tokenBalance = useTokenBalance(bep_20_address, account);
    const TVL = useTokenBalance(bep_20_address, vault2_address);
    const ownsNft = useOwnsNFT();
    const userInfo = useViewUserInfo();
    const dailyYield = useViewUserEstYield();
    const rewards = useCalculateReward();

    const [approveBUSDSpend, setApproveBUSDSpend] = useState(undefined);
    const [stake, setStake] = useState(undefined);

    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(vault2_address, utils.parseEther(approveBUSDSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);


     // Use BUSD
     const { state: stakeBUSDState, send: stakeBUSD, events: stakeBUSDEvents, resetState: resetStakeBUSD } = useStake();
     function handleStake(){
        stakeBUSD(utils.parseEther(stake)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
     }
 
     useEffect(() => {
         if (stakeBUSDState.status === "Success") {
             setOpen(true);
             setSeverity("success");
         }
         else if (stakeBUSDState.status === "Mining") {
             setOpen(true);
             setSeverity("info");
         }
         else if (stakeBUSDState.status === "Fail") {
             setOpen(true);
             setSeverity("error");
         }
         
     }, [stakeBUSDState]);


    // Compound
    const { state: compoundState, send: compound, events: compoundEvents, resetState: resetCompound } = useCompound();
    function handleCompound(){        
        compound().catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (compoundState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (compoundState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (compoundState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [compoundState]);


    // Collect
    const { state: withdrawRewardsState, send: withdrawRewards, events: withdrawRewardsEvents, resetState: resetWithdrawRewards } = useWithdrawRewards();
    function handleWithdrawRewards(){        
        withdrawRewards().catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (withdrawRewardsState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (withdrawRewardsState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (withdrawRewardsState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [withdrawRewardsState]);

    return (
        <div className={ classes.container }>
            <Grid container spacing={8} direction="column" >
                <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid container item>
                    <Grid container direction='row' justifyContent='space-evenly' spacing={3}>
                        <Grid item className={ classes.boxCard }>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.goldText } variant='h6' align='center'>
                                    <strong>Total Value Locked</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                <Typography className={ classes.whiteText } variant='h6' align='center'>
                                    <strong>{TVL ? parseFloat(utils.formatEther(TVL)).toFixed() : "0"} BUSD</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item className={ classes.boxCard }>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.goldText } variant='h6' align='center'>
                                    <strong>APR</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                <Typography className={ classes.whiteText } variant='h6' align='center'>
                                    <strong>{account ? "182.5" : "0"}%</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item className={ classes.boxCard }>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.goldText } variant='h6' align='center'>
                                    <strong>Bank Key/Diamond Gem NFT APR Boost</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                {!ownsNft && (
                                    <Typography>
                                        <Typography className={ classes.whiteText } variant='h6' align='center'>
                                            <strong>+0%</strong>
                                        </Typography>
                                        <Typography className={ classes.whiteText } variant='body2' align='center'>
                                            <strong>(You do not own a BANK KEY or DIAMOND GEM NFT)</strong>
                                        </Typography>
                                    </Typography>
                                )
                                }

                                {ownsNft && (
                                    <Typography>
                                        <Typography className={ classes.greenText } variant='h6' align='center'>
                                            <strong>+182.5%</strong>
                                        </Typography>
                                        <Typography className={ classes.whiteText } variant='body2' align='center'>
                                            <strong>(You own a BANK KEY or DIAMOND GEM NFT)</strong>
                                        </Typography>
                                    </Typography>
                                )
                                }
                                
                            </Box>
                        </Grid>
                        <Grid item className={ classes.boxCard }>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.goldText } variant='h6' align='center'>
                                    <strong>Fees</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                <Typography className={ classes.whiteText } variant='h6' align='center'>
                                    <strong>0</strong>
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid container direction='row' justifyContent='center' spacing={8}>
                        <Grid item>
                            <Card className={ classes.stakingCard }>
                                <CardHeader title=<Typography className={ classes.goldText } variant='h6'>
                                    <strong>STAKE BUSD</strong> 
                                </Typography>/>
                                <CardContent>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>WALLET BALANCE: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed() : "0"} </strong>
                                    </Typography>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>STAKED: {userInfo ? parseFloat(utils.formatEther(userInfo[0])).toFixed() : "0"} </strong> 
                                    </Typography>
                                    <Typography className={ classes.whiteText } variant='body1'>
                                    <strong>EST. DAILY YIELD: {dailyYield ? parseFloat(utils.formatEther(dailyYield)).toFixed(2) : "0"} </strong> 
                                    </Typography>

                                    
                                    <Box display='flex' flexDirection='column' mt={4}>
                                        <Box mb={1}>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>Approve BUSD</strong> 
                                            </Typography>
                                        </Box>
                                        

                                        <TextField
                                        type="number"
                                        className={ classes.textField }
                                        InputProps={{ className: classes.whiteText }}
                                        InputLabelProps={{ className: classes.greyText }}
                                        label="Enter Amount" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setApproveBUSDSpend(event.target.value)}
                                        />

                                        <Button className={ classes.btnColor } variant="contained" onClick={() => handleApproveBUSD()}>
                                            <strong>APPROVE</strong>
                                        </Button>
                                    </Box>

                                    <Box display='flex' flexDirection='column' mt={1} mb={4}>
                                        <Box mb={1}>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>Stake BUSD</strong> 
                                            </Typography>
                                        </Box>
                                        

                                        <TextField
                                        type="number"
                                        className={ classes.textField }
                                        InputProps={{ className: classes.whiteText }}
                                        InputLabelProps={{ className: classes.greyText }}
                                        label="Enter Amount (MIN. 10 BUSD)" 
                                        variant="outlined"
                                        size="small"
                                        onChange={(event) => setStake(event.target.value)}
                                        value = {stake ? stake : ""}
                                        />
                                        
                                        <Button className={ classes.btnColor } variant="contained" onClick={() => handleStake()}>
                                            <strong>STAKE</strong>
                                        </Button>
                                    </Box>

                                    
                                    

                                </CardContent>
                            </Card>
                        </Grid>

                        <Grid item>
                            <Card className={ classes.stakingCard }>
                                <CardHeader title=<Typography className={ classes.goldText } variant='h6'>
                                    <strong>COMPOUND/COLLECT</strong> 
                                </Typography>/>
                                <CardContent>
                                    
                                    
                                    
                                    
                                    <Box display='flex' flexDirection='column' mb={4}>
                                        <Box display='flex' flexDirection='row' justifyContent='center'>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>BUSD EARNED: {rewards ? parseFloat(utils.formatEther(rewards)).toFixed(2) : "0"}</strong> 
                                            </Typography>
                                                
                                            
                                        </Box>
                                        
                                        <Box display='flex' flexDirection='row' justifyContent='center' mt={2}>
                                            <Box mr={1}>
                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleCompound()}>
                                                    <strong>COMPOUND</strong>
                                                </Button>
                                            </Box>
                                            
                                            <Box ml={1}>
                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleWithdrawRewards()}>
                                                    <strong>COLLECT</strong>
                                                </Button>
                                            </Box>
                                            
                                        </Box>
                                    </Box>
                                    
                                    

                                    
                                    

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                
                <Grid container item justifyContent='center'>
                    <Calculator/>
                </Grid>
                
            </Grid>
        </div>    
    );
  }
  
export default Stake;