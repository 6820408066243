import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "rgb(47,93,79)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      wealthText: {
        color: "rgb(181, 244, 181)"
      },
    }
});

function Info() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column">
                <Grid container item justifyContent="center">
                    <Box display="flex" flexDirection="column" justifyContent="center"  alignItems="center">
                        <Typography className={classes.whiteText} variant='h2'>
                            <strong>EARN <Typography className={classes.wealthText} variant='h2' display='inline'><strong>5% or 7% DAILY</strong></Typography>  ON YOUR BUSD</strong> 
                        </Typography>
                        <Typography className={classes.greyText} variant='h5' component='h5' display='block'>
                            No bots. No mining. No inflation. No BS.
                        </Typography>
                        
                        
                    </Box>
                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Info;