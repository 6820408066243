import React, { useState } from "react";
import {
  Grid,
  Slider,
  Typography,
  TextField,
  Checkbox,
  Card,
  CardContent,
  makeStyles,
  CardHeader,
  OutlinedInput,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    backgroundColor: "rgb(40,40,40)",
    maxWidth: "30rem"
  },
  whiteText: {
    color: "#ffffff"
  },
  goldText: {
    color: "#ffe699"
  },
  greyText: {
    color: "#a6a6a6"
  },
  blueText: {
    color: "#6febeb"
  },
}));

const Calculator = () => {
  const classes = useStyles();
  const [initialStake, setInitialStake] = useState(1000);
  const [stakingDuration, setStakingDuration] = useState(30);
  const [isBankKey, setIsBankKey] = useState(false);

  const handleInitialStakeChange = (event, value) => {
    setInitialStake(value);
  };

  const handleStakingDurationChange = (event, value) => {
    setStakingDuration(value);
  };

  const handleBankKeyChange = (event) => {
    setIsBankKey(event.target.checked);
  };

  const calculateROI = () => {
    const dailyROI = isBankKey ? 0.04 : 0.03;
    const totalROI = dailyROI * stakingDuration;
    return initialStake * totalROI;
  };

  return (
    <Card className={classes.card}>
      <CardHeader title=<Typography className={ classes.goldText } variant='h6' align="center">
          <strong>STAKING CALCULATOR</strong> 
      </Typography>/>
      <CardContent>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={9}>
            <Typography className={ classes.whiteText } variant="h6" align="center">
              Staking Amount: ${initialStake}
            </Typography>
            <Slider
              value={initialStake}
              onChange={handleInitialStakeChange}
              step={90}
              min={10}
              max={100000}
              className={classes.goldText}
            />
            <TextField
              type="number"
              value={initialStake}
              onChange={(event) =>
                setInitialStake(parseFloat(event.target.value))
              }
              margin="normal"
              variant="outlined"
              label="Staking Amount (BUSD)"
              InputProps={
                { className: classes.whiteText }
                }
              InputLabelProps={
            { className: classes.whiteText }
            }
            />
          </Grid>
          <Grid item xs={9}>
            <Typography className={ classes.whiteText }variant="h6" align="center">
              Staking Duration: {stakingDuration} days
            </Typography>
            <Slider
              value={stakingDuration}
              onChange={handleStakingDurationChange}
              step={1}
              min={1}
              max={365}
              className={classes.goldText}
            />
            <TextField
              type="number"
              value={stakingDuration}
              onChange={(event) =>
                setStakingDuration(parseInt(event.target.value))
              }
              margin="normal"
              variant="outlined"
              label="Staking Duration (days)"
              InputProps={
                { className: classes.whiteText }
                }
              InputLabelProps={
              { className: classes.whiteText }
              }
            />
          </Grid>
          <Grid item xs={9}>
            <Checkbox
              checked={isBankKey}
              onChange={handleBankKeyChange}
              className={classes.goldText}
              color="#ffe699"
            />
            <Typography className={classes.whiteText} variant="subtitle1" display="inline">
              Bank Key NFT APR Boost (4% daily ROI)
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={ classes.whiteText } variant="h3" align="center">
              ${calculateROI().toFixed(2)} ROI
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Calculator;
