import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../sections/nodes/Navbar.jsx';
import Info from '../sections/nodes/Info.jsx';
import MintNFT from '../sections/nodes/MintNFT.jsx';
import Tiers from '../sections/nodes/Tiers.jsx';
import Stake from '../sections/nodes/Stake.jsx';
import NodeVault from '../sections/nodes/NodeVault.jsx';
import Disclaimer from '../sections/nodes/Disclaimer.jsx';

const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Nodes() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <Navbar/>
            <Info/>
            <MintNFT/>
            <Tiers/>
            <Stake/>
            <NodeVault/>
            <Disclaimer/>
          </div>    
      );
    }
    
  export default Nodes;