import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useEthers, shortenAddress } from '@usedapp/core';
import ConnectWalletBtn from '../../components/ConnectWalletBtn';


import Logo from "../../images/nodes.png";


const useStyles = makeStyles((theme) => {

    return {
        appBar: {
            // background: "radial-gradient(circle, rgba(208,205,240,1) 52%, rgba(209,236,255,1) 100%)",
            background: "linear-gradient(to top, rgb(35,23,58), rgb(32,12,122))",
            boxShadow: "None",
            paddingBottom: theme.spacing(1)
        },
        logo: {
            height: 'auto',
            width: '15rem'
        },
        buttonMargin: {
            marginTop: theme.spacing(3)
          },
          btnColor: {
            color: "#ffffff",
            background: "#5680E9",
            "&:hover": {
                color: "#ffffff",
                backgroundColor:" #91adf2",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: "#5680E9"
              }
            }
          },
    }
});

function Navbar() {
    const classes = useStyles();

    return (
        <div >
            <AppBar className={ classes.appBar } position="relative">
                <Toolbar>
                <Grid container direction="row">
                    <Grid container item>
                    <Box 
                          className={ classes.logo }
                          component="img"
                          alt="BUSD BANK Logo"
                          src={Logo}
                          mt={5}
                      />
                    </Grid>
                </Grid>
                <Grid container item direction='row' lg={6} md={6} xs={6} alignContent="center" className={ classes.buttonMargin }>
                <Box mt={2} >
                      <Button className={ classes.btnColor } variant="contained" href='https://the-stamp.com/2023/07/busd-bank-nodes-audit/' target="_blank">
                          <strong>AUDIT (THE STAMP)</strong>
                      </Button>
                    </Box>
                  <Box mt={2} >
                      <Button className={ classes.btnColor } variant="contained" href='https://docs.busd-bank.com' target="_blank">
                          <strong>DOCUMENTATION</strong>
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <Button className={ classes.btnColor } variant="contained" href='https://t.me/+bF4H93wS3B00NWZh' target="_blank">
                          <strong>TELEGRAM</strong>
                      </Button>
                    </Box>
                    <Box mt={2}>
                      <ConnectWalletBtn classes={classes}/>
                    </Box>
                  </Grid>
                </Toolbar>
            </AppBar>
        </div>    
    );
  }
  
export default Navbar;

