import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import ecosystem from "../../images/eco4.png";



const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "rgb(40,40,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },
      greenText: {
        color: "#50C878"
      },

      bouncingImage: {
        animation: '$bounce 2s infinite',
      },
      '@keyframes bounce': {
        '0%, 100%': {
          transform: 'translateY(0)',
        },
        '50%': {
          transform: 'translateY(-10px)',
        },
      },

      textMt: {
        marginTop: "0.7rem"
      }

    }
});

function Ecosystem() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={5} direction="row" justifyContent='space-between'>
            <Grid item xs={12} md={5}>
            <Typography className={classes.whiteText} variant='h4'>
                <strong>AN <Typography className={classes.goldText} display='inline' variant='h4'><strong>INTERCONNECTED ECOSYSTEM</strong> </Typography> OF <Typography className={classes.greenText} display='inline' variant='h4'><strong>HIGH-YIELD</strong> </Typography> DECENTRALIZED APPLICATIONS</strong> 
            </Typography>
            <Typography className={`${classes.whiteText} ${classes.textMt}`} variant='h6'>
             The <Typography className={classes.goldText} display='inline' variant='h6'>BUSD</Typography>  <Typography className={classes.blueText} display='inline' variant='h6'>BANK</Typography> ecosystem provides users with attractive returns on their investments. By pooling resources and creating synergies between different DApps, users can potentially amplify their yields and capitalize on the interconnected nature of the ecosystem. This approach encourages diversification and allows users to explore a range of investment opportunities while enjoying the potential for higher returns.
            </Typography>

            </Grid>
            <Grid item xs={12} md={5}>
            <Box
                display="flex"
                className={classes.bouncingImage}
                component="img"
                alt="ecosystem"
                src={ecosystem} 
            />
            </Grid>
            

                
            </Grid>
        </div>    
    );
  }
  
export default Ecosystem;