import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import { borders } from '@material-ui/system';

import Snackbars from '../../components/Snackbars';


import bronze from "../../images/nodes/bronze.jpg";
import silver from "../../images/nodes/silver.jpg";
import gold from "../../images/nodes/gold.jpg";

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';


const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          background: "linear-gradient(0deg, #151332 0%, rgba(21, 19, 50, 0) 100%), linear-gradient(282.66deg, rgba(42, 179, 255, 0.19) -6.68%, rgba(117, 32, 255, 0.19) 51.39%, rgba(255, 66, 179, 0.19) 106.96%), #151332",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      redText: {
        color: "#FF0000"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      secondaryText: {
        color: "rgb(164, 158, 189)"
      },
      card: {
        padding: theme.spacing(7, 8, 7),
        backgroundColor: "rgb(50,50,50)"
      },
      btnColor: {
        minWidth: "70%",
        alignSelf:"center",
        color: "#ffffff",
        backgroundColor: "#6c531e",
        "&:hover": {
            color: "#ffffff",
            backgroundColor:"#4d3b15",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },
      card: {
        // display: 'flex',
        // aspectRatio: '2 / 1',
        minWidth: "20rem",
        // minHeight: "18rem",
        marginTop: "3rem",
        background: "linear-gradient(282.66deg, rgba(42, 179, 255, 0.19) -6.68%, rgba(117, 32, 255, 0.19) 51.39%, rgba(255, 66, 179, 0.19) 106.96%), linear-gradient(0deg, #151332 0%, rgba(21, 19, 50, 0) 100%), #151332",

      },
      media: {
        flex: 1,
        // height: '100%',
      },
      content: {
        flex: 1,
        padding: theme.spacing(2),
      },

      timeline: {        
        '& .MuiTimelineItem-root': {
          paddingLeft: 0,
        },
        '& .MuiTimelineContent-root': {
          paddingLeft: theme.spacing(1),
        },
        '& .MuiTimelineOppositeContent-root': {
          flex: 'none',
          marginRight: 0,
          padding: 0,
        },
      },

      
      
    }
});

  

function Tiers() {
    const classes = useStyles();
    const { account } = useEthers();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" >
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid container item >
                    <Grid container alignItems='center' direction='column'>
                        <Grid item>
                            <Typography className={classes.purpleText} variant='h3'>
                                <strong>NODE TIERS</strong> 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.secondaryText} variant='h6' component='h5' display='block' align='center'>
                                Each Node tier provides different yields and compound bonuses, with extra yield being rewarded to NFT holders and those with stakes in BUSD BANK VAULT. 
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item>
                  <Grid container justifyContent='center' spacing={3}>
                    <Grid item xs={12} sm={7} md={6}>
                      

                      <Card className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid container item xs={6}>
                              <Grid container direction='column'>
                              <Grid item>
                                <Typography variant="h5" className={classes.purpleText} align='center'>
                                  <strong>TIER 0 NODE</strong> 
                                </Typography>
                              </Grid>
                              <Grid item>
                              <Timeline className={classes.timeline}>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>YIELD: 1% Daily</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>INITIAL DEPOSIT RANGE: 10-499 BUSD</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>COMPOUND BONUS: 5%</strong> 

                              </Typography></TimelineContent>
                                </TimelineItem>

                                
                              </Timeline> 


                              </Grid>
                              </Grid>
                              

                              

                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                className={classes.media}
                                component="img"
                                alt="TIER 0 NODE"
                                src={bronze}   
                                width="100%"
                              
                            />
                            </Grid>

                          </Grid>
                          


                                                   
                        

                          
                        </CardContent>
                        
                      </Card>


                    </Grid>

                    <Grid item xs={12} sm={7} md={6}>

                      <Card className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid container item xs={6}>
                              <Grid container direction='column'>
                              <Grid item>
                                <Typography variant="h5" className={classes.purpleText} align='center'>
                                  <strong>TIER 1 NODE</strong> 
                                </Typography>
                              </Grid>
                              <Grid item>
                              <Timeline className={classes.timeline}>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>YIELD: 2% Daily</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>INITIAL DEPOSIT RANGE: 500-999 BUSD</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>COMPOUND BONUS: 10%</strong> 

                              </Typography></TimelineContent>
                                </TimelineItem>
                              </Timeline> 


                              </Grid>
                              </Grid>
                              

                              

                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                className={classes.media}
                                component="img"
                                alt="TIER 1 NODE"
                                src={silver}   
                                width="100%"
                              
                            />
                            </Grid>

                          </Grid>
                          


                                                   
                        

                          
                        </CardContent>
                        
                      </Card>


                    </Grid>

                    <Grid item xs={12} sm={7} md={6}>

                      <Card className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid container item xs={6}>
                              <Grid container direction='column'>
                              <Grid item>
                                <Typography variant="h5" className={classes.purpleText} align='center'>
                                  <strong>TIER 2 NODE</strong> 
                                </Typography>
                              </Grid>
                              <Grid item>
                              <Timeline className={classes.timeline}>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>YIELD: 3% Daily</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>INITIAL DEPOSIT RANGE: 1000+ BUSD</strong> 
                              </Typography></TimelineContent>
                                </TimelineItem>
                                <TimelineItem>
                                  <TimelineOppositeContent />
                                  <TimelineSeparator>
                                    <TimelineDot />
                                  </TimelineSeparator>
                                  <TimelineContent><Typography variant="body1" className={classes.secondaryText}>
                                <strong>COMPOUND BONUS: 15%</strong> 

                              </Typography></TimelineContent>
                                </TimelineItem>
                              </Timeline> 


                              </Grid>
                              </Grid>
                              

                              

                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                              <Box
                                // className={classes.media}
                                component="img"
                                alt="TIER 2 NODE"
                                src={gold}   
                                width="100%"
                              
                            />
                            </Grid>

                          </Grid>
                          


                                                   
                        

                          
                        </CardContent>
                        
                      </Card>


                    </Grid>

                  
                  </Grid>

                </Grid>

                
            </Grid>
        </div>    
    );
  }
  
export default Tiers;