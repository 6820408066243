import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';

import Navbar from '../sections/vault2/Navbar';
import Info from '../sections/vault2/Info';
import Stake from '../sections/vault2/Stake';
import Disclaimer from '../sections/vault2/Disclaimer';

const useStyles = makeStyles((theme) => {
    return {
      root: {
          flexGrow: 1
      }
    }
  });
  
  function Main2() {
      const classes = useStyles();
      return (
          <div className={ classes.root }>
            <Navbar/>
            <Info/>
            <Stake/>
            <Disclaimer/>
          </div>    
      );
    }
    
  export default Main2;