import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";

import {
  bep_20_contract,
  bep_20_interface,
  bep_20_address,

  wealth_interface,
  wealth_address,
  wealth_contract,

} from '../../constants/contracts';

export function useViewUserInfo() {
  const { account } = useEthers();
  const userInfo = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "viewUserInfo",
    args: [account], 
  }) ?? [];
  
  if (userInfo.length !== 0){
    return userInfo;
  }
  return [0, 0, 0, 0];
}

export function useViewUserEstYield() {
  const { account } = useEthers();
  const [dailyYield] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "viewUserEstYield",
    args: [account], 
  }) ?? [];
  
  return dailyYield;
}

export function useCalculateReward() {
  const { account } = useEthers();
  const [reward] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "calculateReward",
    args: [account], 
  }) ?? [];
  
  return reward;
}

export function useOwnsBankKeyNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "ownsBankKeyNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}

export function useOwnsGemNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "ownsGemNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}

export function useHasBusdBankStake() {
  const { account } = useEthers();
  const [hasStake] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "hasBusdBankStake",
    args: [account], 
  }) ?? [];
  
  return hasStake;
}

export function useHasNotEmergencyWithdraw() {
  const { account } = useEthers();
  const [hasNotWithdraw] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "hasNotEmergencyWithdraw",
    args: [account], 
  }) ?? [];
  
  return hasNotWithdraw;
}

export function useHasNodesStake() {
  const { account } = useEthers();
  const [hasStake] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "hasNodesStake",
    args: [account], 
  }) ?? [];
  
  return hasStake;
}

export function useChecklistComplete() {
  const { account } = useEthers();
  const [checklist] = useContractCall({
    abi: wealth_interface,
    address: wealth_address,
    method: "checklistComplete",
    args: [account], 
  }) ?? [];
  
  return checklist;
}


export function useApproveBUSD(){
    const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, events, resetState };
}


export function useStake(){
  const { state, send, events, resetState } = useContractFunction(wealth_contract, "stake");
  return { state, send, events, resetState };
}

export function useCompound(){
  const { state, send, events, resetState } = useContractFunction(wealth_contract, "compound");
  return { state, send, events, resetState };
}

export function useWithdrawRewards(){
  const { state, send, events, resetState } = useContractFunction(wealth_contract, "withdrawRewards");
  return { state, send, events, resetState };
}