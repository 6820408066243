import { useEthers, useContractFunction, useCall, useTokenBalance, useContractCall } from "@usedapp/core";
import { constants, utils, BigNumber } from "ethers";

import {
  bep_20_contract,

  busd_bank_interface,
  busd_bank_address,

  bank_key_contract,
  bank_key_interface,
  bank_key_address

} from '../constants/contracts';



export function useApproveBUSD(){
    const { state, send, events, resetState } = useContractFunction(bep_20_contract, "approve");
    return { state, send, events, resetState };
}


export function useMint(){
  const { state, send, events, resetState } = useContractFunction(bank_key_contract, "safeMint");
  return { state, send, events, resetState };
}

export function useViewTokenCount() {
    const [tokenCount] = useContractCall({
      abi: bank_key_interface,
      address: bank_key_address,
      method: "viewTokenCount",
      args: [], 
    }) ?? [];
    
    return tokenCount;
  }

export function useOwnsNFT() {
  const { account } = useEthers();
  const [ownsNft] = useContractCall({
    abi: busd_bank_interface,
    address: busd_bank_address,
    method: "ownsNFT",
    args: [account], 
  }) ?? [];
  
  return ownsNft;
}