import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { useSearchParams, createSearchParams } from "react-router-dom"

import ButtonGroup from '@material-ui/core/ButtonGroup';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';

import { borders } from '@material-ui/system';

import Snackbars from '../../components/Snackbars';


import bronze from "../../images/nodes/bronze.jpg";
import silver from "../../images/nodes/silver.jpg";
import gold from "../../images/nodes/gold.jpg";

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';

import { nodes_address, bep_20_address } from "../../constants/contracts";
import { useOwnsGemNFT, useOwnsKeyNFT, useHasBusdBankStake, useStake, useViewUserInfo, useApproveBUSD, useViewUserEstYield, useCompound, useWithdrawRewards, useCalculateReward } from '../../hooks/nodes/nodeHooks.jsx';


const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
        backgroundColor: "rgb(11,1,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      redText: {
        color: "#FF0000"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      secondaryText: {
        color: "rgb(164, 158, 189)"
      },
    
      btnColor: {
        // minWidth: "70%",
        // alignSelf:"center",
        color: "#ffffff",
        backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
        
      },

      btnColor2: {
        // minWidth: "70%",
        // alignSelf:"center",
        color: "#ffffff",
        backgroundImage: "linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB)",
        display: "block"
        
      },

      card: {
        // display: 'flex',
        // aspectRatio: '2 / 1',
        minWidth: "20rem",
        // minHeight: "18rem",
        marginTop: "3rem",
        // background: "linear-gradient(282.66deg, rgba(42, 179, 255, 0.19) -6.68%, rgba(117, 32, 255, 0.19) 51.39%, rgba(255, 66, 179, 0.19) 106.96%), linear-gradient(0deg, #151332 0%, rgba(21, 19, 50, 0) 100%), #151332",
        backgroundColor: "#151332"
      },

      avatar: {
        minWidth: "6rem",
        minHeight: "6rem",
      },

      textFieldMarginB: {
        marginBottom: "0.3rem"
      },

      textFieldMarginT: {
        marginTop: "1rem"
      },

      divider: {
        backgroundColor: "rgb(164, 168, 255)",
        minHeight: "0.12rem",
      },

      boxCard: {
        padding: theme.spacing(2, 3, 2),
        minWidth: "16rem",
        backgroundColor: "#151332"
      },

      BoxesMarginT: {
        marginTop: "3rem"
      },

      notchedOutline: {
        borderColor: "rgb(164, 158, 189) !important",
      },
      textFieldColorRef: {
        color: "rgb(164, 158, 189) !important",
        fontWeight: "bold",
        fontSize:"1rem",
      },
      
      
    }
});

  

function Stake() {
    const classes = useStyles();
    const { account } = useEthers();

    // referrer
    const [searchParams, setSearchParams] = useSearchParams({});
    const myReferrer = searchParams.get("ref"); 
    const [ref, setRef] = useState("");  
    useEffect(() => {
        if(!myReferrer) {
            if (ref === "") {
                setRef("0x0000000000000000000000000000000000000000");
            }
        }
        else{
            if (ref === "") {
                setRef(myReferrer);
            }
        }
      }, [myReferrer]);

    const [refLink, setRefLink] = useState("");
    if ((account !== undefined) && (refLink === "")){
        setRefLink(`https://busd-bank.com/nodes?ref=${account}`);
    }

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const tokenBalance = useTokenBalance(bep_20_address, account);
    const TVL = useTokenBalance(bep_20_address, nodes_address);
    const ownsBankNft = useOwnsKeyNFT();
    const ownsGemNft = useOwnsGemNFT();
    const hasBankStake = useHasBusdBankStake();

    // TIER 0
    const userInfoTier0 = useViewUserInfo(0);
    const dailyYieldTier0 = useViewUserEstYield(0);
    const rewardsTier0 = useCalculateReward(0);
    const [stakeTier0, setStakeTier0] = useState(undefined);

    // TIER 1
    const userInfoTier1 = useViewUserInfo(1);
    const dailyYieldTier1 = useViewUserEstYield(1);
    const rewardsTier1 = useCalculateReward(1);
    const [stakeTier1, setStakeTier1] = useState(undefined);

    // TIER 2
    const userInfoTier2 = useViewUserInfo(2);
    const dailyYieldTier2 = useViewUserEstYield(2);
    const rewardsTier2 = useCalculateReward(2);
    const [stakeTier2, setStakeTier2] = useState(undefined);

    const [approveBUSDSpend, setApproveBUSDSpend] = useState(undefined);

    
    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(nodes_address, utils.parseEther(approveBUSDSpend)).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);


    // Use BUSD
    const { state: stakeBUSDState, send: stakeBUSD, events: stakeBUSDEvents, resetState: resetStakeBUSD } = useStake();
    function handleStake(stake, tier){
        
        stakeBUSD(utils.parseEther(stake), ref, tier).catch(err => {
            setOpen(true);
            setSeverity("warning");
        });
        
        
    }

    useEffect(() => {
        if (stakeBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (stakeBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (stakeBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [stakeBUSDState]);


   // Compound
   const { state: compoundState, send: compound, events: compoundEvents, resetState: resetCompound } = useCompound();
   function handleCompound(tier){        
       compound(tier).catch(err => {
           setOpen(true);
           setSeverity("warning");
       })
   }

   useEffect(() => {
       if (compoundState.status === "Success") {
           setOpen(true);
           setSeverity("success");
       }
       else if (compoundState.status === "Mining") {
           setOpen(true);
           setSeverity("info");
       }
       else if (compoundState.status === "Fail") {
           setOpen(true);
           setSeverity("error");
       }
       
   }, [compoundState]);


   // Collect
   const { state: withdrawRewardsState, send: withdrawRewards, events: withdrawRewardsEvents, resetState: resetWithdrawRewards } = useWithdrawRewards();
   function handleWithdrawRewards(tier){        
       withdrawRewards(tier).catch(err => {
           setOpen(true);
           setSeverity("warning");
       })
   }

   useEffect(() => {
       if (withdrawRewardsState.status === "Success") {
           setOpen(true);
           setSeverity("success");
       }
       else if (withdrawRewardsState.status === "Mining") {
           setOpen(true);
           setSeverity("info");
       }
       else if (withdrawRewardsState.status === "Fail") {
           setOpen(true);
           setSeverity("error");
       }
       
   }, [withdrawRewardsState]);



    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" alignItems='center'>
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid container item >
                    <Grid container alignItems='center' direction='column'>
                        <Grid item>
                            <Typography className={classes.purpleText} variant='h3'>
                                <strong>MY NODES</strong> 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.secondaryText} variant='h6' component='h5' display='block'>
                                Manage your deposits, compounds, and withdrawels. 
                            </Typography>
                        </Grid>
                        
                    </Grid>
                </Grid>

                <Grid container item className={classes.BoxesMarginT}>
                    <Grid container direction='row' justifyContent='space-evenly' spacing={3}>
                        <Grid item className={ classes.boxCard } xs={5}>
                            <Box display="flex" flexDirection="column">
                                <Typography className={ classes.purpleText } variant='h6' align='center'>
                                    <strong>Total Value Locked</strong> 
                                </Typography>
                                <Box mt={2}></Box>
                                <Typography className={ classes.whiteText } variant='h6' align='center'>
                                    <strong>{TVL ? parseFloat(utils.formatEther(TVL)).toFixed() : "0"} BUSD</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        
                        
                    </Grid>

                </Grid>

                <Grid container item>
                    <Grid container justifyContent='space-around' spacing={4}>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardHeader
                                    subheader=<Typography variant="body1" className={classes.secondaryText} align='center'>
                                    <strong>1% Daily Yield</strong> 
                                
                                    </Typography>
                                    avatar={
                                    <Avatar src={bronze} className={classes.avatar}/>
                                    }
                                    title=<Typography variant="h5" className={classes.purpleText} align='center'>
                                    <strong>TIER 0 NODE</strong> 
                                
                                    </Typography>

                                    
                                />

                                <CardContent>
                                    <Grid container direction='column' spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DEPOSIT</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{userInfoTier0 ? parseFloat(utils.formatEther(userInfoTier0[0])).toFixed() : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DAILY YIELD BONUS %</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>BANK KEY NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsBankNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsBankNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>DIAMOND GEM NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsGemNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsGemNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>$1500+ STAKE IN BUSD BANK VAULT: </strong>
                                            <Typography variant="body1" className={hasBankStake ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{hasBankStake ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>
                                            
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>REWARDS EARNED</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={`${classes.whiteText} ${classes.textFieldMarginB}`} >
                                            <strong>{rewardsTier0 ? parseFloat(utils.formatEther(rewardsTier0)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>

                                            <ButtonGroup variant="contained">
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium'>
                                                    <Typography variant="subtitle2" className={classes.whiteText} onClick={() => handleCompound(0)}>
                                                    <strong>COMPOUND</strong> 
                                                    </Typography>
                                                    <Typography variant="subtitle2" className={classes.whiteText}>
                                                    <strong>(+5% Bonus)</strong> 
                                                    </Typography>
                                                </Button>
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium' onClick={() => handleWithdrawRewards(0)}>
                                                    <strong>WITHDRAW</strong>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>EST. DAILY YIELD</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{dailyYieldTier0 ? parseFloat(utils.formatEther(dailyYieldTier0)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Divider className={ classes.divider }/>
                                        </Grid>

                                        

                                        <Grid container item>
                                            <Grid container direction='column'>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>WALLET BALANCE: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed() : "0"} </strong>
                                            </Typography>
                                            <Box mb={0.3} mt={1}>
                                                <Typography variant="h6" className={classes.purpleText} >
                                                <strong>DEPOSIT</strong> 
                                                </Typography>
                                            </Box>

                                            <Grid item className={classes.textFieldMarginB}>
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setApproveBUSDSpend(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleApproveBUSD()}>
                                                    <strong>APPROVE</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item >
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount (Min. 10)" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setStakeTier0(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" size='medium' onClick={() => handleStake(stakeTier0, 0)}>
                                                    <strong>DEPOSIT</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item className={classes.textFieldMarginT}>
                                            
                                                <Typography variant="body2" className={classes.whiteText} align='center'>
                                                <strong>{userInfoTier0 ? (parseFloat(utils.formatEther(userInfoTier0[0])).toFixed() > 0 ?  "" : "Please make an initial deposit between 10-499 BUSD") : "Please make an initial deposit between 10-499 BUSD"}</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center'>
                                                <strong>You can make unlimited deposits of any amount after this.</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center' display='block'>
                                                <strong>There is a 10% Deposit Fee.</strong> 
                                                </Typography>

                                            </Grid>

                                            
                                            
                                            

                                        
                                            </Grid>

                                            
                                            
                                            
                                            

                                        
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                            

                        </Grid>

                        <Grid item>
                            <Card className={classes.card}>
                                <CardHeader
                                    subheader=<Typography variant="body1" className={classes.secondaryText} align='center'>
                                    <strong>2% Daily Yield</strong> 
                                
                                    </Typography>
                                    avatar={
                                    <Avatar src={silver} className={classes.avatar}/>
                                    }
                                    title=<Typography variant="h5" className={classes.purpleText} align='center'>
                                    <strong>TIER 1 NODE</strong> 
                                
                                    </Typography>

                                    
                                />

                                <CardContent>
                                    <Grid container direction='column' spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DEPOSIT</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{userInfoTier1 ? parseFloat(utils.formatEther(userInfoTier1[0])).toFixed() : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DAILY YIELD BONUS %</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>BANK KEY NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsBankNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsBankNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>DIAMOND GEM NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsGemNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsGemNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>$1500+ STAKE IN BUSD BANK VAULT: </strong>
                                            <Typography variant="body1" className={hasBankStake ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{hasBankStake ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>
                                            
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>REWARDS EARNED</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={`${classes.whiteText} ${classes.textFieldMarginB}`} >
                                            <strong>{rewardsTier1 ? parseFloat(utils.formatEther(rewardsTier1)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>

                                            <ButtonGroup variant="contained">
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium'>
                                                    <Typography variant="subtitle2" className={classes.whiteText} onClick={() => handleCompound(1)}>
                                                    <strong>COMPOUND</strong> 
                                                    </Typography>
                                                    <Typography variant="subtitle2" className={classes.whiteText}>
                                                    <strong>(+10% Bonus)</strong> 
                                                    </Typography>
                                                </Button>
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium' onClick={() => handleWithdrawRewards(1)}>
                                                    <strong>WITHDRAW</strong>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>EST. DAILY YIELD</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{dailyYieldTier1 ? parseFloat(utils.formatEther(dailyYieldTier1)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Divider className={ classes.divider }/>
                                        </Grid>

                                        

                                        <Grid container item>
                                            <Grid container direction='column'>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>WALLET BALANCE: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed() : "0"} </strong>
                                            </Typography>
                                            <Box mb={0.3} mt={1}>
                                                <Typography variant="h6" className={classes.purpleText} >
                                                <strong>DEPOSIT</strong> 
                                                </Typography>
                                            </Box>

                                            <Grid item className={classes.textFieldMarginB}>
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setApproveBUSDSpend(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleApproveBUSD()}>
                                                    <strong>APPROVE</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item >
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount (Min. 10)" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setStakeTier1(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" size='medium' onClick={() => handleStake(stakeTier1, 1)}>
                                                    <strong>DEPOSIT</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item className={classes.textFieldMarginT}>
                                                <Typography variant="body2" className={classes.whiteText} align='center'>
                                                <strong>{userInfoTier1 ? (parseFloat(utils.formatEther(userInfoTier1[0])).toFixed() > 0 ?  "" : "Please make an initial deposit between 500-999 BUSD") : "Please make an initial deposit between 500-999 BUSD"}</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center'>
                                                <strong>You can make unlimited deposits of any amount after this.</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center' display='block'>
                                                <strong>There is a 10% Deposit Fee.</strong> 
                                                </Typography>

                                            </Grid>

                                            
                                            
                                            

                                        
                                            </Grid>

                                            
                                            
                                            
                                            

                                        
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                            

                        </Grid>


                        <Grid item>
                            <Card className={classes.card}>
                                <CardHeader
                                    subheader=<Typography variant="body1" className={classes.secondaryText} align='center'>
                                    <strong>3% Daily Yield</strong> 
                                
                                    </Typography>
                                    avatar={
                                    <Avatar src={gold} className={classes.avatar}/>
                                    }
                                    title=<Typography variant="h5" className={classes.purpleText} align='center'>
                                    <strong>TIER 2 NODE</strong> 
                                
                                    </Typography>

                                    
                                />

                                <CardContent>
                                    <Grid container direction='column' spacing={3}>
                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DEPOSIT</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{userInfoTier2 ? parseFloat(utils.formatEther(userInfoTier2[0])).toFixed() : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>MY DAILY YIELD BONUS %</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>BANK KEY NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsBankNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsBankNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>DIAMOND GEM NFT YIELD: </strong>
                                            <Typography variant="body1" className={ownsGemNft ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{ownsGemNft ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>

                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>$1500+ STAKE IN BUSD BANK VAULT: </strong>
                                            <Typography variant="body1" className={hasBankStake ? classes.greenText : classes.secondaryText} display='inline'>
                                            <strong>{hasBankStake ? "+1%" : "+0%"}</strong> 
                                            </Typography>
                                            </Typography>
                                            
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>REWARDS EARNED</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={`${classes.whiteText} ${classes.textFieldMarginB}`} >
                                            <strong>{rewardsTier2 ? parseFloat(utils.formatEther(rewardsTier2)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>

                                            <ButtonGroup variant="contained">
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium'>
                                                    <Typography variant="subtitle2" className={classes.whiteText} onClick={() => handleCompound(2)}>
                                                    <strong>COMPOUND</strong> 
                                                    </Typography>
                                                    <Typography variant="subtitle2" className={classes.whiteText}>
                                                    <strong>(+15% Bonus)</strong> 
                                                    </Typography>
                                                </Button>
                                                 <Button className={ classes.btnColor2 } variant="contained" size='medium' onClick={() => handleWithdrawRewards(2)}>
                                                    <strong>WITHDRAW</strong>
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>

                                        <Grid item>
                                            <Typography variant="h6" className={classes.purpleText} >
                                            <strong>EST. DAILY YIELD</strong> 
                                            </Typography>
                                            <Typography variant="body1" className={classes.whiteText}>
                                            <strong>{dailyYieldTier2 ? parseFloat(utils.formatEther(dailyYieldTier2)).toFixed(2) : "0"} BUSD</strong> 
                                            </Typography>
                                        </Grid>

                                        <Grid item>
                                            <Divider className={ classes.divider }/>
                                        </Grid>

                                        

                                        <Grid container item>
                                            <Grid container direction='column'>
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>WALLET BALANCE: {tokenBalance ? parseFloat(utils.formatEther(tokenBalance)).toFixed() : "0"} </strong>
                                            </Typography>
                                            <Box mb={0.3} mt={1}>
                                                <Typography variant="h6" className={classes.purpleText} >
                                                <strong>DEPOSIT</strong> 
                                                </Typography>
                                            </Box>

                                            <Grid item className={classes.textFieldMarginB}>
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setApproveBUSDSpend(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" onClick={() => handleApproveBUSD()}>
                                                    <strong>APPROVE</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item >
                                                <TextField
                                                type="number"
                                                className={ classes.textField }
                                                
                                                InputProps={{ className: classes.whiteText } }
                                                InputLabelProps={{ className: classes.greyText }}
                                                label="Enter Amount (Min. 10)" 
                                                variant="outlined"
                                                size="small"
                                                
                                                onChange={(event) => setStakeTier2(event.target.value)}
                                                />

                                                <Button className={ classes.btnColor } variant="contained" size='medium' onClick={() => handleStake(stakeTier2, 2)}>
                                                    <strong>DEPOSIT</strong>
                                                </Button>
                                            </Grid>

                                            <Grid item className={classes.textFieldMarginT}>
                                                <Typography variant="body2" className={classes.whiteText} align='center'>
                                                <strong>{userInfoTier2 ? (parseFloat(utils.formatEther(userInfoTier2[0])).toFixed() > 0 ?  "" : "Please make an initial deposit of 1000+ BUSD") : "Please make an initial deposit of 1000+ BUSD"}</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center'>
                                                <strong>You can make unlimited deposits of any amount after this.</strong> 
                                                </Typography>
                                                <Typography variant="caption" className={classes.secondaryText} align='center' display='block'>
                                                <strong>There is a 10% Deposit Fee.</strong> 
                                                </Typography>

                                            </Grid>

                                            
                                            
                                            

                                        
                                            </Grid>

                                            
                                            
                                            
                                            

                                        
                                        </Grid>

                                    </Grid>
                                </CardContent>
                            </Card>
                            

                        </Grid>



                    </Grid>

                </Grid>

                <Grid item md={6}>
                <Box className={classes.boxCard} display='flex' flexDirection='column' mt={4}>
                                        <Box display='flex' justifyContent='center'>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>REFERRAL LINK (EARN 4%)</strong> 
                                            </Typography>
                                                
                                            
                                        </Box>

                                        <Box mt={2} justifyContent="center" display="flex">
                                
                                                <TextField
                                                    InputProps={{
                                                        classes: {
                                                            notchedOutline: classes.notchedOutline,
                                                            input: classes.textFieldColorRef
                                                        },
                                                        readOnly: true
                                                    }}
                                                    variant="outlined"
                                                    fullWidth
                                                    value={refLink}
                                                    
                                                />

                                                <Button className={classes.btnColor} onClick={() =>  navigator.clipboard.writeText(`${refLink}`)}>Copy</Button>
                                
                                        </Box>

                                        <Box display='flex' flexDirection='column' alignItems='center' mt={2}>
                                            
                                            <Typography className={ classes.whiteText } variant='body1'>
                                            <strong>REFERRAL REWARDS EARNED: 0</strong> 
                                            </Typography>
                                            <Typography className={ classes.secondaryText } variant='caption'>
                                        <strong>Referral rewards are automatically deposited into your wallet</strong> 
                                        </Typography>
                                            
                                                
                                            
                                        </Box>
                                        


                                    </Box>

                </Grid>
                

            </Grid>
        </div>    
    );
  }
  
export default Stake;