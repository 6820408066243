import Main from "./pages/Main.jsx";
import Nodes from "./pages/Nodes.jsx";
import Welcome from "./pages/Welcome.jsx";
import Wealth from "./pages/Wealth.jsx";
import Main2 from "./pages/Main2.jsx";

import {DAppProvider, ChainId} from '@usedapp/core';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";




function App() {
  return (
    <DAppProvider config={{multicallAddresses: {
      [ChainId.Localhost]: '0x137f7c84559D20ce1F01b684a45ead90e79140ce'
    },
    }}>
      <Router>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/vault" element={<Main />} />
          <Route path="/nodes" element={<Nodes />} />
          <Route path="/wealth" element={<Wealth />} />
          <Route path="/vault_two" element={<Main2 />} />
        </Routes>
      </Router>
    </DAppProvider>
    
  );
}

export default App;
