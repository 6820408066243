import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { borders } from '@material-ui/system';

import Snackbars from '../../components/Snackbars';


import diamond from "../../images/nodes/diamond2.png";

import { constants, utils, BigNumber } from "ethers";
import { useEthers, useTokenBalance } from '@usedapp/core';

import { gem_address, bep_20_address } from "../../constants/contracts";
import { useApproveBUSD, useMint, useViewTokenCount, useOwnsGemNFT } from '../../hooks/nodes/nodeHooks';

const useStyles = makeStyles((theme) => {
    const btnColor = "rgb(50,50,50)"

    return {
      container: {
          backgroundColor: "rgb(11,1,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      greenText: {
        color: "#50C878"
      },
      redText: {
        color: "#FF0000"
      },
      purpleText: {
        color: "rgb(164, 168, 255)"
      },
      secondaryText: {
        color: "rgb(164, 158, 189)"
      },
      card: {
        padding: theme.spacing(7, 8, 7),
        backgroundColor: "rgb(50,50,50)"
      },
      btnColor: {
        minWidth: "70%",
        alignSelf:"center",
        color: "#ffffff",
        backgroundColor: "#6c531e",
        "&:hover": {
            color: "#ffffff",
            backgroundColor:"#4d3b15",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: btnColor
          }
        }
      },

      button85: {
        minWidth: "15rem",
        marginTop: "1rem",
        padding: '0.6em 2em',
        border: 'none',
        outline: 'none',
        color: 'rgb(255, 255, 255)',
        background: '#111',
        cursor: 'pointer',
        position: 'relative',
        zIndex: 0,
        borderRadius: 10,
        userSelect: 'none',
        WebkitUserSelect: 'none',
        touchAction: 'manipulation',
        '&:before': {
          content: "''",
          background: 'linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000)',
          position: 'absolute',
          top: -2,
          left: -2,
          backgroundSize: '400%',
          zIndex: -1,
          filter: 'blur(5px)',
          WebkitFilter: 'blur(5px)',
          width: 'calc(100% + 4px)',
          height: 'calc(100% + 4px)',
          animation: '$glowingButton85 20s linear infinite',
          transition: 'opacity 0.3s ease-in-out',
          borderRadius: 10,
        },
        '&:after': {
          zIndex: -1,
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: '#222',
          left: 0,
          top: 0,
          borderRadius: 10,
        },
      },
      '@keyframes glowingButton85': {
        '0%': {
          backgroundPosition: '0 0',
        },
        '50%': {
          backgroundPosition: '400% 0',
        },
        '100%': {
          backgroundPosition: '0 0',
        },
      },

      ownershipMargin: {
        marginTop: "1rem",
        marginBottom: "1rem"
      }
      
    }
});

  

function MintNFT() {
    const classes = useStyles();
    const { account } = useEthers();

    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const totalMinted = useViewTokenCount();
    const ownsNft = useOwnsGemNFT();

    // mint nft
    const { state: mintState, send: mint, events: mintEvents, resetState: resetMint} = useMint();
    function handleMint(){        
        mint(account, utils.parseEther("100")).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (mintState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (mintState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (mintState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [mintState]);


    // approve busd
    const { state: approveBUSDState, send: approveBUSD, events: approveBUSDEvents, resetState: resetApproveBUSD } = useApproveBUSD();
    function handleApproveBUSD(){        
        approveBUSD(gem_address, utils.parseEther("100")).catch(err => {
            setOpen(true);
            setSeverity("warning");
        })
    }

    useEffect(() => {
        if (approveBUSDState.status === "Success") {
            setOpen(true);
            setSeverity("success");
        }
        else if (approveBUSDState.status === "Mining") {
            setOpen(true);
            setSeverity("info");
        }
        else if (approveBUSDState.status === "Fail") {
            setOpen(true);
            setSeverity("error");
        }
        
    }, [approveBUSDState]);

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" >
            <Snackbars open={open} setOpen={setOpen} severity={severity}/>
                <Grid container item >
                    <Grid container alignItems='center' direction='column'>
                        <Grid item>
                            <Typography className={classes.purpleText} variant='h3'>
                                <strong>MINT A DIAMOND GEM NFT</strong> 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.secondaryText} variant='h6' component='h5' display='block'>
                                Minting a DIAMOND GEM NFT grants you an additional 1% daily return in BUSD BANK NODES. 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography className={classes.secondaryText} variant='h6' component='h5' display='block'>
                                And additional benefits in future ecosystem projects. 
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container item>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item>
                            <Box
                                component="img"
                                alt="DIAMOND NFT"
                                src={diamond}
                                height="20rem"
                                width="20rem"
                                alignSelf="center"
                                mt={5}
                            />
                            <Typography className={classes.whiteText} variant='h6' align='center'>
                                <strong>Total Minted: {totalMinted ? utils.formatUnits(totalMinted, 0) : "0"}/999</strong> 
                            </Typography>
                            
                            
                        </Grid>

                    </Grid>

                </Grid>

                <Grid container item>
                    <Grid container direction='column' justifyContent='center' alignItems='center'>
                        <Grid item className={classes.ownershipMargin}>
                          <Typography align='center'>
                              {ownsNft ? <Typography className={classes.greenText} variant='h6'><strong>You own a DIAMOND GEM NFT</strong></Typography> : <Typography className={classes.redText} variant='h6'><strong>You currently do not own a DIAMOND GEM NFT</strong></Typography>}
                          </Typography>
                        </Grid>
                        <Grid item>
                            <Button className={ classes.button85 } target="_blank" variant="outlined" onClick={() => handleApproveBUSD()}>
                                <strong>Approve 100 BUSD</strong> 
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button className={ classes.button85 } target="_blank" variant="outlined" onClick={() => handleMint()}>
                                <strong>MINT</strong> 
                            </Button>
                        </Grid>
                    
                    </Grid>

                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default MintNFT;