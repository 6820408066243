import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

import BUSDBANK from '../abi/BUSDBANK';
import BEP20Token from '../abi/BEP20Token.json';
import BANKKEY from '../abi/BANKKEY.json';

import BUSDBANKNODES from '../abi/BUSDBANKNODES.json';
import GEM from '../abi/GEM.json';

import BUSDBANKWEALTH from '../abi/BUSDBANKWEALTH.json';

import BUSDBANK2 from '../abi/BUSDBANK2.json';



// testnet
// export const busd_bank_abi = BUSDBANK.abi
// export const busd_bank_address = "0x5A97ffDD8B2Fd88ce372D74bCd7cF401770f0f49"
// export const busd_bank_interface = new utils.Interface(busd_bank_abi)
// export const busd_bank_contract = new Contract(busd_bank_address, busd_bank_interface)

// export const bep_20_abi = BEP20Token.abi
// export const bep_20_address = "0x173cef2c9b77ee1643e2f9334f783775097a7995" 
// export const bep_20_interface = new utils.Interface(bep_20_abi)
// export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

// export const bank_key_abi = BANKKEY.abi
// export const bank_key_address = "0x6d7D056e45480A0650492d536873678f42A0accf" 
// export const bank_key_interface = new utils.Interface(bank_key_abi)
// export const bank_key_contract = new Contract(bank_key_address, bank_key_interface)

// export const nodes_abi = BUSDBANKNODES.abi
// export const nodes_address = "0xef7d77ed3ab0DF48B4059E158e66591002BD4AA4" 
// export const nodes_interface = new utils.Interface(nodes_abi)
// export const nodes_contract = new Contract(nodes_address, nodes_interface)

// export const gem_abi = GEM.abi
// export const gem_address = "0x853001b4a345DA4CFEd62C2D7E3e4f9F8C8C2836" 
// export const gem_interface = new utils.Interface(gem_abi)
// export const gem_contract = new Contract(gem_address, gem_interface)

// export const wealth_abi = BUSDBANKWEALTH.abi
// export const wealth_address = "0x0fb16241179d7F76423e9420d71873D0f42F629f" 
// export const wealth_interface = new utils.Interface(wealth_abi)
// export const wealth_contract = new Contract(wealth_address, wealth_interface)


// PRODUCTION
export const busd_bank_abi = BUSDBANK.abi
export const busd_bank_address = "0x9b6F9CFD86BbAf9ACAEF415de3E4Da02Ab5832cc"
export const busd_bank_interface = new utils.Interface(busd_bank_abi)
export const busd_bank_contract = new Contract(busd_bank_address, busd_bank_interface)

export const bep_20_abi = BEP20Token.abi
export const bep_20_address = "0xe9e7cea3dedca5984780bafc599bd69add087d56" 
export const bep_20_interface = new utils.Interface(bep_20_abi)
export const bep_20_contract = new Contract(bep_20_address, bep_20_interface)

export const bank_key_abi = BANKKEY.abi
export const bank_key_address = "0xB5234861658a89CEAEFd4e0275918BCFBc3A86D2" 
export const bank_key_interface = new utils.Interface(bank_key_abi)
export const bank_key_contract = new Contract(bank_key_address, bank_key_interface)


export const nodes_abi = BUSDBANKNODES.abi
export const nodes_address = "0x2C89F2debC4942536609b7B52dF65d0f95E31195" 
export const nodes_interface = new utils.Interface(nodes_abi)
export const nodes_contract = new Contract(nodes_address, nodes_interface)

export const gem_abi = GEM.abi
export const gem_address = "0x610Ca7F2Be302B97392CB69A3018A9a1CC095139" 
export const gem_interface = new utils.Interface(gem_abi)
export const gem_contract = new Contract(gem_address, gem_interface)


export const wealth_abi = BUSDBANKWEALTH.abi
export const wealth_address = "0xE4706bF7466F17041efbB1448AC6105CC06E7AC5" 
export const wealth_interface = new utils.Interface(wealth_abi)
export const wealth_contract = new Contract(wealth_address, wealth_interface)

export const vault2_abi = BUSDBANK2.abi
export const vault2_address = "0x7A31cd5c42363A6d682ef264CDd09Fc901d9517A" 
export const vault2_interface = new utils.Interface(vault2_abi)
export const vault2_contract = new Contract(vault2_address, vault2_interface)