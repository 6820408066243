import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import logo from "../../images/busd_bank.png";


const useStyles = makeStyles((theme) => {
    return {
      container: {
          backgroundColor: "rgb(40,40,40)",
          padding: theme.spacing(4, 8, 6),
      },
      whiteText: {
        color: "#ffffff"
      },
      goldText: {
        color: "#ffe699"
      },
      greyText: {
        color: "#a6a6a6"
      },
      blueText: {
        color: "#6febeb"
      },

      card: {
        backgroundColor: "rgb(50,50,50)"
      },

      containerMt: {
        marginTop: "3rem"
      }
    }
});

function Reasons() {
    const classes = useStyles();

    return (
        <div className={ classes.container }>
            <Grid container spacing={1} direction="column" justifyContent="center" alignItems='center' alignContent='center'>
                <Grid item>
                    <Typography className={classes.goldText} variant='h3'>
                        <strong>WHAT MAKES US SPECIAL</strong> 
                    </Typography>
                </Grid>

                <Grid container item className={classes.containerMt}>
                  <Grid container justifyContent='space-between' spacing={5}>
                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>SCALABILITY & FUTURE-PROOF DESIGN</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          Our project is built with scalability and future-proofing in mind. The underlying architecture is designed to handle increasing user demand and adapt to evolving market dynamics. By investing in the ecosystem, you become part of a platform that is equipped to grow and thrive in the long run.

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>ROBUST SECURITY MEASURES</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          Your investments are of utmost importance to us. We have implemented rigorous security measures, including multiple audits by reputable third-party firms, to ensure the safety and integrity of our platform. Your funds are protected with the highest level of security protocols.

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>COMMUNITY-DRIVEN DECISIONS</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          We believe in the power of community. As investors in our project, you have a say in the decision-making process. We actively seek and value feedback from our community members, allowing you to actively participate in shaping the future direction of the ecosystem.

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                    

                    

                    

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>ENDLESS INVESTMENT LOOP</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          Break free from the cycle of single-project failures and explore the transformative potential of our interconnected ecosystem. Unlike singular projects that often face a swift decline and risk going to zero, our ecosystem's strength lies in its diversified nature. By encompassing multiple interconnected projects, we mitigate the risk of individual project failures and create a robust framework for long-term sustainability.

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>TRUSTED TEAM</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          We've been around for a while! We are the founders of MIM Machine & MIM Star Miner and have multiple years of experience building Decentralized Applications. By leveraging our extensive experience, we have crafted an interconnected ecosystem of high-yield decentralized applications that reflect our unwavering commitment to quality and innovation. We strive for excellence in every aspect of our operations, from meticulous project selection to rigorous due diligence, ensuring that our users have access to the most promising investment opportunities.

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                    <Grid item md={4}>
                      <Card className={classes.card}>
                        <CardHeader
                          title=<Typography className={classes.goldText} variant='h5'>
                            <strong>NO BS POLICY</strong> 
                        </Typography>
                        />
                        <CardContent>
                          <Typography className={classes.whiteText} variant='h6'>
                          No backdoors. No Dev access to funds. No rug pulls. No inflation. No bots. Simply put, NO BS! 

                          </Typography>
                        </CardContent>

                      </Card>


                    </Grid>

                  </Grid>

                </Grid>
            </Grid>
        </div>    
    );
  }
  
export default Reasons;